import React from "react";
import IconQilu from '@/images/h5-common/logo_qilu.webp';

function AboutApp() {
  return (
      <div className='flex flex-col items-center ml-[20px] mr-[20px]'>
        <img
            src={IconQilu}
            alt='logo'
            className='object-cover w-[90px] h-[90px] mt-[32px] mb-[32px]'
        />
        <div className='flex flex-col text-[#333333] text-[14px] font-normal'>
          "奇鹿"是一款专为英语学习的定制的互动体验软件，它利用创新技术和AI辅助发音工具，创造了一个轻松愉悦的环境，帮助大家在探索中拓展英语能力。我们致力于打破传统的语言训练方式，让过程更加生动和高效。
          <br/><br/>
          【多样化互动体验】
          我们提供了一系列互动体验，包括词卡记忆游戏、拼写挑战、听力填空等，这些游戏化的方式不仅能够激发大家的好奇心，还能有效加强大家的语言感知能力。
          <br/><br/>
          【个性化拓展计划】
          利用智能算法，我们为大家定制了个性化的拓展计划，确保大家在探索过程中既有序又高效，帮助大家逐步建立起坚实的语言基础。
          <br/><br/>
          【智能AI助手】
          我们的App内置了AI发音辅助功能，能够提供即时的发音反馈。无论是单个音节还是完整句子，智能AI助手都能协助大家改善发音，提升语言的自然度。
          <br/><br/>
        </div>
        <div className='text-[#333333] text-[14px] font-normal'>
          上海鹿奇飞科技发展有限公司 版权所有
        </div>
        <div className='text-[#333333] text-[14px] font-normal mb-[20px]'>
          沪ICP备2024071020号-3A
        </div>

      </div>
  );
}

export default AboutApp;
