export function isMobile() {
  const userAgent = navigator.userAgent;

  // 检查是否为移动设备
  if (/Mobile|Android|iPhone|iPod|iPad|Windows Phone|luqifei/.test(userAgent)) {
    return true;
  }

  // 如果没有匹配到任何关键词，则认为是PC端
  return false;
}

export function isPad() {
  const userAgent = navigator.userAgent;

  // 检查是否为移动设备
  if (/pad_true/.test(userAgent)) {
    return true;
  }

  // 如果没有匹配到任何关键词，则认为是PC端
  return false;
}
